import { PageModel, PageModels, SiteModelKeys } from '../../models/SiteModel';
import {
  analyticsDataRegistry,
  EventDetail,
  trackEvent,
  TrackingPageSection,
} from '@vp/digital-tracking-lib';
import SocialLinksModel from '../../models/SocialLinksModel';

const FEATURES_TAB = 'Features Tab';
const STYLE_TAB = 'Style Tab';
const TRACKING_ACTION = 'Design Tool Used';

function createEventDetail(key: SiteModelKeys, data: PageModels): typeof EventDetail {
  let eventName = EventNamePrefix[key];
  if (key === 'socialLinksModel') {
    eventName += `:${(data as SocialLinksModel).platform}`;
  }
  return `${eventName} Edited` as unknown as typeof EventDetail;
}
type EventNameMapping = Record<SiteModelKeys, string>;

export const EventNamePrefix: EventNameMapping = {
  emailUsModel: 'Email us',
  contactsModel: 'Add to contact',
  aboutModel: 'About',
  callNowModel: 'Call now',
  operationHoursModel: 'Hours of operation',
  socialLinksModel: 'Social link',
  videoModel: 'Video',
  websiteModel: 'Website',
  galleryModel: 'Gallery',
  themesModel: 'Style',
  customLinkModel: 'Custom link',
  uploadPDFModel: 'PDF Upload',
  addressModel: 'Address',
};
const { connectedBusinessCardsStudio } = analyticsDataRegistry;

export function trackFeatureEvent(formData: PageModel) {
  if (formData['key'] === 'themesModel') {
    return;
  }

  trackEvent(
    TRACKING_ACTION,
    FEATURES_TAB,
    createEventDetail(formData.key, formData.data),
    connectedBusinessCardsStudio!.pageSection!,
    connectedBusinessCardsStudio!.pageStage!,
    connectedBusinessCardsStudio!.pageName || '',
    TrackingPageSection.STUDIO_BUILDER
  );
}

function getStyleEventDetail(aria: string, action: string, pageZone?: string) {
  const section = pageZone ? `${aria}:${pageZone}` : aria;
  return `${section}:${action}` as unknown as typeof EventDetail;
}

export function trackStyleEvent(aria: string, action: string, pageZone?: string) {
  const trackingData = {
    action: TRACKING_ACTION,
    label: STYLE_TAB,
    eventDetail: getStyleEventDetail(aria, action, pageZone),
    pageSection: connectedBusinessCardsStudio!.pageSection!,
    pageStage: connectedBusinessCardsStudio!.pageStage!,
    pageName: connectedBusinessCardsStudio!.pageName || '',
    category: TrackingPageSection.STUDIO_BUILDER,
  };

  trackEvent(
    trackingData.action,
    trackingData.label,
    trackingData.eventDetail,
    trackingData.pageSection,
    trackingData.pageStage,
    trackingData.pageName || '',
    trackingData.category
  );
}
